@import './vendor/fonts.css';
@import './mixins.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

div.ant-typography {
    @apply font-roboto;
}

span.ant-typography {
    @apply font-poppins;
}

@layer base {
    html {
        @apply text-[10px] 2xs:text-[11px] sm:text-[12px] md:text-[13px] lg:text-base;
    }

    body {
        @apply font-poppins font-normal;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-poppins font-medium !important;
    }

    input {
        @apply font-normal;
    }

    label {
        @apply font-poppins;
    }

    a {
        @apply text-primary dark:text-primary-dark;
    }

    a, button {
        &:focus {
            @apply outline-none;
        }
    }

    svg {
        padding: 0 2px !important;
    }

    tbody {
        @apply font-roboto;
    }

    .table-cell-data {
        &.string {
            @apply text-left;
        }

        &.boolean {
            @apply text-center;
        }

        &.number {
            @apply text-right;
        }
    }

    ul[class*="ReactFlagsSelect"] {
        &::-webkit-scrollbar {
            width: theme('fontSize.5xs');
            height: theme('fontSize.5xs');
        }

        &::-webkit-scrollbar-thumb {
            background: theme('colors.success.DEFAULT');
        }

        &::-webkit-scrollbar-track {
            background-color: theme('colors.default.DEFAULT');
        }

        & {
            scrollbar-face-color: theme('colors.success.DEFAULT');
            scrollbar-track-color: theme('colors.default.DEFAULT');
        }
    }

    .dark ul[class*="ReactFlagsSelect"] {
        &::-webkit-scrollbar-thumb {
            background: theme('colors.success.dark');
        }

        &::-webkit-scrollbar-track {
            background-color: theme('colors.default.dark');
        }

        & {
            scrollbar-face-color: theme('colors.success.dark');
            scrollbar-track-color: theme('colors.default.dark');

            @apply bg-background-dark;

            & li:hover {
                @apply bg-default-hover-dark;
            }

            & div[class*="filterBox"] {
                @apply bg-background-dark;
            }

            & input[placeholder="Search"] {
                @apply bg-background-dark;
            }
        }
    }
}

@layer utilities {
    .stroke-white {
        -webkit-text-stroke: 0.1px #ffffff;
        text-stroke: 0.1px #ffffff;
    }

    .stroke-none {
        -webkit-text-stroke: unset;
        text-stroke: unset;
    }
}

@mixin scrollbars theme('fontSize.5xs'), theme('colors.success.DEFAULT'), theme('colors.default.DEFAULT'), light;
@mixin scrollbars theme('fontSize.5xs'), theme('colors.success.dark'), theme('colors.default.dark'), dark;
