@define-mixin scrollbars $size, $foreground-color, $background-color, $theme {
    .scrollbar-$(theme) {
        overflow-y: auto;

        /* For Google Chrome */

        &::-webkit-scrollbar {
            width: $size;
            height: $size;
        }

        &::-webkit-scrollbar-thumb {
            background: $foreground-color;
        }

        &::-webkit-scrollbar-track {
            background-color: $background-color;
        }

        /* For Internet Explorer */

        & {
            scrollbar-face-color: $foreground-color;
            scrollbar-track-color: $background-color;
        }
    }
}